.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: pink ;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color:black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skillsContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 50%;
}

.skillItem {
    background: aqua;
    border: 0.1em solid gray;
    list-style-type: none;
    padding: 0.2em;
}
ul.skills {
  padding:0;
  margin:0;
}
input {
  width: 100%;
  padding: 0;
  margin: 0;
}
